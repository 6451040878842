import TimeUtility from '@/util/TimeUtility.js';

// THOSE VALIDATION ERRORS ARE NOT USED IN BE YET. AFTER THEY ARE USED THEY CAN BE IMPLEMENTED
// CONTRACT
// const CONTRACT_HAS_PRODUCT_THAT_REQUIRES_DEVICE_OF_TYPE_BUT_NONE_WAS_GIVEN = '(PARAM_CONTRACT_ID ,PARAM_DEVICE_TYPE ) The contract with id %s is invalid, because it a device of type %s and none was given.';
// const CONTRACT_ALREADY_HAS_DEVICE_OF_TYPE = '(PARAM_CONTRACT_ID ,PARAM_DEVICE_TYPE ) The contract with id %s is invalid, because it already has a device of type %s.';
// const ORDER_HOUSE_CONNECTION_IS_MISSING_AT_ADDRESS = '()';

// HOW To IMPLEMENT?
// PRODUCTS
// const PRODUCT_MISSING_REQUIRED_PRODUCT = 'The %s requires the %s product.';
// const PRODUCT_NOT_VALID = '(PARAM_PRODUCT_ID) The product with id %d is not valid.';
// PARAM Fallback is deprecated
function buildErrorMessage(errorCodeArray, fallback) {
  var errorMessage = [];
  var timestamp = new Date().toLocaleString('de-DE');
  if (Array.isArray(errorCodeArray)) {
    for (const element of errorCodeArray) {
      if (element.code !== null && element.code !== undefined) {
        errorMessage.push(
          ` ${buildErrorMessageFromDto(element)} Zeitstempel: ${timestamp}`
        );
      }
    }
  } else if (typeof errorCodeArray === 'object' && errorCodeArray !== null) {
    errorMessage.push(`${fallback} | Zeitstempel: ${timestamp}`);
  } else {
    errorMessage.push(
      `Es ist ein Fehler aufgetreten. Fehlernachricht: ${errorCodeArray}; Zeitstempel: ${timestamp}`
    );
  }
  if (errorMessage.length === 0) {
    errorMessage.push(fallback);
  }
  return errorMessage;
}

function buildErrorMessages(errorDtos) {
  var errorMessages = [];
  for (const element of errorDtos) {
    errorMessages.push(buildErrorMessageFromDto(element));
  }
  return errorMessages;
}

function buildErrorMessageFromDto(errorDto) {
  var code = errorDto.code;
  var paramTaskId,
    fieldname,
    message,
    contractId,
    productId,
    productType,
    deviceType,
    deviceName,
    serviceDataId,
    carrierId,
    customerId,
    serialNumber,
    fileName,
    articleName,
    cwmpAccount,
    contractType,
    addressAvailabilityStatus,
    documentId,
    documentName,
    requiredDocumentTypes,
    retoureReceptionDate,
    trackingId,
    subscriber;
  switch (code) {
    // TASK
    case 'TASK_ID_IS_INVALID':
      paramTaskId = errorDto.payload.taskId;
      return `Die angegebene taskId is ungültig: ${paramTaskId}`;
    // DATE
    case 'DATE_COULD_NOT_BE_PARSED':
      fieldname = errorDto.payload.fieldName;
      return `${fieldname} ist kein gültiges Datum.`;
    case 'DATE_IS_IN_THE_PAST':
      fieldname = errorDto.payload.fieldName;
      return `${fieldname} darf nicht in der Vergangenheit liegen.`;
    case 'MANDATORY_PARAMETER_MISSING':
      fieldname = errorDto.payload.fieldName;
      return `Zwingender Parameter ${fieldname} fehlt.`;
    // FIELDS
    case 'FIELD_FAILED_JAVA_X_VALIDATION':
      message = errorDto.payload.message;
      fieldname = errorDto.payload.propertyPath;
      return `Interner server Fehler bezüglich dem Feld ${fieldname} : ${message}.`;

    case 'GENERIC_FIELD_ERROR':
      fieldname = errorDto.payload.fieldName;
      message = errorDto.payload.message;
      return `Fehler mit Feld ${fieldname}: ${message}.`;
    // PROCESSES
    case 'PROCESS_WITH_CONTRACT_ID_ALREADY_RUNNING':
      return 'Ein Prozess für die angegebene Vertrags ID wurde bereits gestartet.';

    case 'MULTIPLE_PROCESSES_WITH_CONTRACT_ID_ALREADY_RUNNING':
      return 'Mehrere Prozesse mit der angegebenen Vertrags ID wurden bereits gestartet.';

    case 'PROCESS_WITH_CONTRACT_ID_AND_PHONE_NUMBERS_ALREADY_RUNNING':
      return 'Ein Prozess für die angegebene Vertrags ID und eine der angegebenen Telefonnummern wurde bereits gestartet.';

    // CONTRACT
    case 'CONTRACT_NO_ID_WAS_PROVIDED':
      return 'Es wurde keine Vertrags ID angegeben.';
    case 'CONTRACT_IS_NOT_AVAILABLE':
      return 'Der Vertrag ist nicht verfügbar.';
    case 'CONTRACT_ALREADY_HAS_PRODUCT':
      contractId = errorDto.payload.contractId;
      productType = errorDto.payload.productType;
      return `Der Vertrag mit der ID ${contractId} hat bereits den Produkttyp ${productType}.`;
    case 'CONTRACT_HAS_NO_PRODUCTS_THAT_REQUIRE_DEVICE_OF_TYPE':
      deviceType = errorDto.payload.deviceType;
      if (deviceType === 'CPE_DEVICE') {
        deviceName = 'CPE';
      } else if (deviceType === 'ONT_DEVICE') {
        deviceName = 'ONT';
      } else if (deviceType === 'TV_DEVICE') {
        deviceName = 'TV-Stick';
      } else {
        deviceName = deviceType;
      }
      contractId = errorDto.payload.contractId;
      return `Für den Vertrag mit ID ${contractId} muss ein Gerät vom Typ ${deviceName} verschickt werden, dem Vertrag ist allerdings kein entsprechendes Produkt zugeordnet.`;
    // PHONE NUMBERS
    case 'PHONE_NUMBERS_DO_NOT_BELONG_TO_CONTRACT':
      return 'Einige der angegebenen Telefonnummern gehören nicht zum Vertrag.';
    // DOCUMENTS
    case 'DOCUMENT_DOES_NOT_EXIST':
      documentId = errorDto.payload.documentId;
      documentId = errorDto.payload.documentName;
      return `Das Dokument '${documentName}' mit der ID ${documentId} existiert nicht.`;

    case 'SOME_DOCUMENTS_ARE_OF_WRONG_TYPE':
      documentId = errorDto.payload.documentId;
      documentId = errorDto.payload.documentName;
      requiredDocumentTypes = errorDto.payload.documentName;
      return `Das Dokument '${documentName}' mit der ID ${documentId} muss einem der folgenden Dokumententypen entsprechen: ${requiredDocumentTypes}.`;
    // VOICEDATA_PRODUCTS
    case 'VOICEDATA_PRODUCTS_NO_AVAILABLE_PRODUCT':
      return 'Es existiert kein voicedata Produkt, welches nicht deaktiviert ist.';
    case 'VOICEDATA_PRODUCTS_MULTIPLE_AVAILABLE_PRODUCTS':
      return 'Es gibt mehrere voicedata Produkte, welche nicht deaktiviert sind.';
    // COMPONENT
    case 'COMPONENT_WITH_ID_IS_ALREADY_ASSIGNED_TO_CONTRACT':
      serviceDataId = errorDto.payload.serviceDataId;
      return `Die Komponente mit der ID ${serviceDataId} ist ungültig, weil sie bereits einem Vertrag zugeordnet wurde.`;
    case 'COMPONENT_WITH_ID_DOES_NOT_EXIST':
      serviceDataId = errorDto.payload.serviceDataId;
      return `Die Komponente mit der ID ${serviceDataId} ist ungültig, weil sie nicht in dimari existiert.`;
    case 'EXISTING_COMPONENT_IS_OF_WRONG_TYPE':
      deviceType = errorDto.payload.deviceType;
      serviceDataId = errorDto.payload.serviceDataId;
      return `Die Komponente mit der ID ${serviceDataId} ist ungültig, weil es nicht vom Geräte Typ ${deviceType} ist.`;
    case 'CONTRACT_HAS_MULTIPLE_PRODUCTS_THAT_REQUIRE_DEVICE_OF_TYPE':
      deviceType = errorDto.payload.deviceType;
      contractId = errorDto.payload.contractId;
      return `Der Vertrag mit der ID ${contractId} ist ungültig, weil er mehrere Geräte vom Typ ${deviceType} benötigt.`;
    // CARRIER
    case 'CARRIER_INVALID':
      carrierId = errorDto.payload.carrierId;
      return `Ungültiger Anbieter: ${carrierId}.`;
    // PRODUCT
    case 'PRODUCT_DOES_NOT_EXIST':
      productId = errorDto.payload.productId;
      return `Das Produkt mit der ID ${productId} existiert nicht in Dimari.`;
    case 'PRODUCT_DOES_NOT_HAVE_EXPECTED_PRODUCT_TYPE':
      productId = errorDto.payload.productId;
      return `Das Produkt mit der ID ${productId} hat nicht den erwarteten Produkttyp.`;
    // ORDER ENTRY
    case 'ORDER_CUSTOMER_ALREADY_EXISTS_AT_LOCATION':
      customerId = errorDto.payload.customerId;
      return `Bestellkunde ${customerId} existiert bereits am Standort.`;
    case 'ORDER_IS_ALREADY_PLACED_AT_ADDRESS':
      contractId = errorDto.payload.contractId;
      contractType = errorDto.payload.contractType;
      addressAvailabilityStatus = errorDto.payload.addressAvailabilityStatus;
      return `Die Bestellung existiert bereits an der Adresse: (${contractId}, ${contractType}, ${addressAvailabilityStatus}).`;
    case 'ORDER_ADDRESS_MUST_BE_IN_EXPANSION_AREA':
      // This error code gets an empty payload from BE
      return 'ORDER_ADDRESS_MUST_BE_IN_EXPANSION_AREA';
    case 'ORDER_PHONE_BOOK_ENTRY_USER_DEFINED_PHONE_BOOK_ENTRY':
      // This error code gets an empty payload from BE
      return 'Es muss eine Adresse für den Nutzer definierten Telefonbucheintrag definiert werden.';
    case 'ORDER_PHONE_BOOK_INVERSE_SEARCH_CAN_NOT_BE_ALLOWED':
      // This error code gets an empty payload from BE
      return 'ORDER_PHONE_BOOK_INVERSE_SEARCH_CAN_NOT_BE_ALLOWED';
    case 'PROVISIONING_PROCESS_AT_LEAST_ONE_SERVICE_MUST_BE_SELECTED':
      // This error code gets an empty payload from BE
      return 'PROVISIONING_PROCESS_AT_LEAST_ONE_SERVICE_MUST_BE_SELECTED';
    case 'SELOCA_ARTICLE_NOT_FOUND':
      productType = buildProductTypeLabel(errorDto.payload.productType);
      return `Kein ${productType} wurde für den automatischen Seloca-Versand gefunden.`;
    case 'SELOCA_FEEDBACK_MISSING_ARTICLE':
      productType = buildProductTypeLabel(errorDto.payload.productType);
      return `Es wurde kein ${productType} in der Seloca-Rückmeldedatei gefunden.`;
    case 'SELOCA_FEEDBACK_MISSING_SERIAL_NUMBER':
      productType = buildProductTypeLabel(errorDto.payload.productType);
      return `Es wurde für den ${productType} keine Seriennummer in der Seloca-Rückmeldedatei gefunden.`;
    case 'SELOCA_FEEDBACK_MISSING_ROUTER_CWMP':
      return 'Für den Router wurde kein CWMP-Account in der Seloca-Rückmeldedatei gefunden.';
    case 'SELOCA_FEEDBACK_MISSING_ROUTER_SERIAL_NUMBER':
      return 'Für den Router wurde keine Seriennummer in der Seloca-Rückmeldedatei gefunden.';
    case 'SELOCA_FEEDBACK_SERIAL_NUMBER_MISSING_IN_DIMARI':
      productType = buildProductTypeLabel(errorDto.payload.productType);
      serialNumber = errorDto.payload.serialNumber;
      return `Die in der Seloca-Rückmeldedatei hinterlegte Seriennummer ${serialNumber} für den ${productType} wurde nicht in Dimari gefunden und kann daher nicht automatisch im Vertrag hinterlegt werden.`;
    case 'SELOCA_FEEDBACK_CWMP_ACCOUNT_MISSING_IN_DIMARI':
      cwmpAccount = errorDto.payload.cwmpAccount;
      serialNumber = errorDto.payload.serialNumber;
      return `Der in der Seloca-Rückmeldedatei hinterlegte CWMP-Account ${cwmpAccount} und Seriennummer ${serialNumber} wurde nicht in Dimari gefunden und kann daher nicht automatisch im Vertrag hinterlegt werden.`;
    case 'SELOCA_FEEDBACK_COULD_NOT_BE_PARSED':
      fileName = errorDto.payload.fileName;
      return `Die Seloca-Rückmeldedatei ${fileName} konnte wegen eines Fehlers nicht verarbeitet werden.`;
    case 'SELOCA_ARTICLE_OUT_OF_STOCK':
      articleName = errorDto.payload.articleName;
      return `Es befinden sich derzeit nicht genügend Artikel '${articleName}' im Lager.`;
    case 'SELOCA_RETOURE_RECEPTION_DATE':
      retoureReceptionDate = TimeUtility.formatRetoureDateTime(
        errorDto.payload.retoureReceptionDate
      );
      return `Eingangsdatum ${retoureReceptionDate}`;
    case 'SELOCA_TRACKING_ID':
      trackingId = errorDto.payload.trackingId;
      return `Tracking ID ${trackingId}`;
    case 'PROVISIONING_PROCESS_AT_LEAST_ONE_DEVICE_REQUIRED':
      // This error code gets an empty payload from BE
      return 'Mindestens ein Gerät ist erforderlich.';
    case 'PROVISIONING_PROCESS_NO_VOICE_TARIFF':
      // This error code gets an empty payload from BE
      return 'Der Vertrag enthält keinen Volumentarif.';
    case 'MSAN_SETTING_MSAN_PORT_FAILED':
      return 'Der MSAN-Port konnte innerhalb der Middleware nicht gesetzt werden.';
    case 'RELEASE_CONSTRUCTION_PROJECT_STATE_CHANGE_USER_ERROR':
      return 'Die Bearbeitung dieser Aufgabe muss durch einen anderen Benutzer erfolgen (4-Augen-Prinzip).';
    case 'REJECTION_MESSAGE_MISSING_ERROR':
      return 'Es muss ein Grund für die Ablehnung angegeben werden.';
    case 'STATISTIC_NO_INSTALLATION_ADDRESSES_FOUND_IN_PROJECT':
      return 'Es konnten keine Installationsadressen im Projekt gefunden werden.';
    case 'STATISTIC_NO_FTTH_ADDRESSES_WITHIN_PROJECT_INSTALLATION_ADDRESSES':
      return 'Innerhalb der im Projekt ermittelten Installationsadressen konnten keine FTTH Adressen gefunden werden.';
    case 'STATISTIC_PROJECT_POLYGON_INCORRECT':
      return 'Das Polygon des Projektes ist fehlerhaft.';
    case 'STATISTIC_DEFAULT_ERROR_MESSAGE':
      return 'Es ist ein Fehler mit den Daten aus dem PAST System aufgetreten.';
    case 'PROJECT_STATE_CHANGE_REQUEST_ALREADY_EXISTS':
      return 'Es existiert bereits eine Anfrage zur Projektstatusänderung für dieses Projekt.';
    case 'NO_INSTALLATION_ADDRESSES_FOUND_IN_PROJECT':
      return 'Es wurden keine Installationsadressen im Projekt gefunden';
    case 'PASSWORD_DOES_NOT_MATCH_REQUIREMENTS':
      subscriber = errorDto.payload.subscriber;
      return `Das Passwort für die Kennung ${subscriber} erfüllt nicht die Akzeptanzkriterien.`;
    default:
      return `Unbekannter Fehlercode ${code} erhalten.`;
  }
}

function buildProductTypeLabel(productType) {
  if (productType === 'CPE_DEVICE') {
    return 'Router';
  } else if (productType === 'ONT_DEVICE') {
    return 'ONT';
  } else {
    return 'Zubehör';
  }
}

export default {
  buildErrorMessage,
  buildErrorMessages,
  buildErrorMessageFromDto,
  buildProductTypeLabel
};
